import { inject, Injectable, InjectionToken } from '@angular/core';
import { FeatureFlag } from 'src/app/common/feature-flags';
import { environment } from 'src/environments/environment';

export const FEATURE_FLAGS = new InjectionToken<FeatureFlag[]>('FEATURE_FLAGS', {
    factory: () => (environment.enabledFeatureFlags?.split(',') ?? []) as FeatureFlag[],
});

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
    private enabledFeatureFlags: FeatureFlag[] = inject(FEATURE_FLAGS);

    public isFeatureFlagEnabled(feature: FeatureFlag) {
        return this.enabledFeatureFlags.includes(feature);
    }
}
