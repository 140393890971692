import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReplaySubject, Subject, map } from 'rxjs';
import { AuthService } from 'src/app/shared/modules/auth/auth.service';

export type UserRole = 'kyc-officer' | 'merchant-user' | 'admin';
export const userRoleOrder: UserRole[] = ['kyc-officer', 'merchant-user'];

export interface User {
    userId: string;
    name: string;
    email: string;
    roles: UserRole[];
}

export const nullUser: User = {
    userId: '',
    name: '',
    email: '',
    roles: [],
};

@Injectable({ providedIn: 'root' })
export class UserService {
    private _user$: Subject<User> = new ReplaySubject<User>(1);
    public user$ = this._user$.asObservable();

    public roles$ = this.user$.pipe(map((user) => user.roles));

    private _user: User = nullUser;
    public get user(): User {
        return this._user;
    }
    private set user(user: User) {
        this._user$.next(user);
    }

    public loggedIn$ = this.user$.pipe(map((user) => !!user.userId));

    constructor(private _authService: AuthService) {
        this._authService.user$
            .pipe(
                map(
                    (user) =>
                        (user
                            ? {
                                  ...user,
                                  roles: ['merchant-user'].concat(user.roles.map((role) => role.toLocaleLowerCase())),
                              }
                            : nullUser) as User,
                ),
                map((user) => ({
                    ...user,
                    roles: user.roles.sort((a, b) => userRoleOrder.indexOf(a) - userRoleOrder.indexOf(b)),
                })),
                takeUntilDestroyed(),
            )
            .subscribe((user) => this._user$.next(user));
        this._user$.pipe(takeUntilDestroyed()).subscribe((user) => (this._user = user));
    }
}
